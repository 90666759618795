import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import {
  Chip,
  Pagination,
  PaginationItem,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SelectedDialog, User, UserTableData, initialState } from "./Types";
import { useDispatch, useSelector } from "react-redux";

import { AxiosResponse } from "axios";
import { DialogContext } from "../../common/Layout/OldKundundeLayout";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import MailIcon from "../../Icons/MailIcon";
import MoonLoader from "react-spinners/MoonLoader";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import UserDetails from "./UserDetails";
import dayjs from "dayjs";
import { getUserOverviewTableData } from "./Api";
import { makeStyles } from "@mui/styles";
import { sortActions } from "../../store/SortSlice";
import { useDebounce } from "use-debounce";
import { useQuery } from "react-query";
import ChangeMeterModal from "../../components/ChangeMeterModal";
import { EditMeterIcon } from "../../Icons/icons";

interface StatusComponentProps {
  id: number;
}

const tableStyles = makeStyles({
  tableHead: {
    height: 44,
  },
});

const UserTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [rowId, setRowId] = useState("");
  const { dialogDispatch } = useContext(DialogContext);
  const [page, setPage] = useState(1);
  const [emptyRow, setEmptyRow] = useState(0);
  const [pagesCount, setPagesCount] = useState(1);
  const [rowsPerPage] = useState(13);
  const [tableData, setTableData] = useState({} as UserTableData);
  const sortState = useSelector((state: any) => state.sortSlice);
  let triggerTime: any;
  const filterState = useSelector(
    (state: any) => state.filterSlice.filterState
  );
  const sortDispatch = useDispatch();
  const classes = tableStyles();

  const searchState = useSelector((state: any) => state.searchSlice);

  useEffect(() => {
    sortDispatch(
      sortActions.changeSort({
        ...initialState,
        ids: {
          asc: false,
          desc: true,
        },
      })
    );
  }, [sortDispatch]);

  useEffect(() => {
    setPage(1);
  }, [filterState, searchState, sortState]);

  const memorizedValue = useMemo(() => {
    let statusTOStr = "status=";

    for (let key in filterState) {
      if (filterState[key] && key !== "all") {
        statusTOStr += key + ",";
      }
    }
    let sortByCol = "&colname=";
    for (let key in sortState) {
      if (sortState[key].asc || sortState[key].desc) {
        sortByCol += key;
        let sortBy = "&sort=";
        if (sortState[key].asc) {
          sortBy += "ASC";
        }
        if (sortState[key].desc) {
          sortBy += "DESC";
        }
        sortByCol += sortBy;
      }
    }
    const searchStr = "&search=" + searchState.searchQuery;
    return (
      statusTOStr + searchStr + "&offset=" + page + "&limit=" + 13 + sortByCol
    );
  }, [filterState, searchState, sortState, page]);
  const [queryToSearch] = useDebounce(memorizedValue, 500);

  const { data, isLoading, isFetching } = useQuery(
    ["UsersTable", queryToSearch],
    {
      queryFn: getUserOverviewTableData,
      refetchIntervalInBackground: false,
      retryDelay: 3,
      refetchInterval: 10 * 1000 * 60, //3 minutes
    }
  );

  useEffect(() => {
    modifedData(data);
  }, [data]);

  const modifedData = useCallback(
    (data: AxiosResponse<any, any> | undefined) => {
      if (data?.data?.data) {
        const mappedData: User[] = data?.data?.data?.map((user: User) => {
          return {
            ...user,
            fornavn: user.fornavn + " " + user.etternavn,
            updated_at: dayjs(user.updated_at).format("DD MMM YYYY HH:mm"),
            bolig: user.bolig + " " + user.postnummer + " " + user.sted,
          };
        });
        setEmptyRow(
          page > 0 ? Math.max(0, rowsPerPage - mappedData?.length) : 0
        );
        setPagesCount((prevValue) => {
          if (data?.data.totalPages) {
            return data?.data.totalPages;
          } else {
            return 1;
          }
        });
        setTableData({
          ...data?.data,
          result: mappedData,
        });
      }
    },
    [data, isLoading, pagesCount]
  );
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const showUserDetails = async (UserId: number) => {
    dialogDispatch({
      type: SelectedDialog.OPEN,
      payload: { UserId },
    });
  };

  const changeSorting = (name: string) => {
    if (!(sortState[name].asc || sortState[name].desc)) {
      sortDispatch(
        sortActions.changeSort({
          ...initialState,
          [name]: {
            asc: true,
            desc: false,
          },
        })
      );
    } else if (sortState[name].asc) {
      sortDispatch(
        sortActions.changeSort({
          ...initialState,
          [name]: {
            asc: false,
            desc: true,
          },
        })
      );
    } else if (sortState[name].desc) {
      if (name === "ids") {
        sortDispatch(
          sortActions.changeSort({
            ...initialState,
            ids: {
              asc: true,
              desc: false,
            },
          })
        );
      } else {
        sortDispatch(
          sortActions.changeSort({
            ...initialState,
            ids: {
              asc: false,
              desc: true,
            },
          })
        );
      }
      // console.log(sortState[name]);
    }
  };
  return (
    <>
      <UserDetails />
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: 3,
          px: 0.5,
        }}
      >
        <Table
          sx={{
            minWidth: 650,
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell
                align="left"
                component={"th" as any}
                scope="row"
              ></TableCell>
              <TableCell
                align="left"
                variant="header"
                onClick={() => changeSorting("ids")}
              >
                <TableSortLabel
                  IconComponent={() => <SortingCustomComponent name="ids" />}
                >
                  SR.NO
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                variant="header"
                onClick={() => changeSorting("fullname")}
              >
                <TableSortLabel
                  IconComponent={() => (
                    <SortingCustomComponent name="fullname" />
                  )}
                >
                  Full name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={"asc"}
                align="left"
                onClick={() => changeSorting("status")}
                variant="header"
              >
                <TableSortLabel
                  IconComponent={() => <SortingCustomComponent name="status" />}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell
                onClick={() => changeSorting("email")}
                align="left"
                variant="header"
              >
                <TableSortLabel
                  IconComponent={() => <SortingCustomComponent name="email" />}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                variant="header"
                onClick={() => changeSorting("mobilenumber")}
              >
                <TableSortLabel
                  IconComponent={() => (
                    <SortingCustomComponent name="mobilenumber" />
                  )}
                >
                  Mobile
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                onClick={() => changeSorting("personnummer")}
                variant="header"
              >
                <TableSortLabel
                  IconComponent={() => (
                    <SortingCustomComponent name="personnummer" />
                  )}
                >
                  Personnummer
                </TableSortLabel>
              </TableCell>
              <TableCell
                onClick={() => changeSorting("address")}
                align="left"
                variant="header"
              >
                <TableSortLabel
                  IconComponent={() => (
                    <SortingCustomComponent name="address" />
                  )}
                >
                  Address
                </TableSortLabel>
              </TableCell>
              <TableCell
                align="left"
                variant="header"
                onClick={() => changeSorting("updated_at")}
              >
                <TableSortLabel
                  IconComponent={() => (
                    <SortingCustomComponent name="updated_at" />
                  )}
                >
                  Updated
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading &&
              tableData?.result?.map((row: User, index: number) => (
                <TableRow
                  key={String(index)}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    paddingInline: 10,
                  }}
                >
                  <TableCell align="left" component={"th" as any} scope="row">
                    <Stack direction={"row"} spacing={1}>
                      <MailIcon />
                    </Stack>
                  </TableCell>
                  <TableCell align="left" variant="cell">
                    {row.ids}
                  </TableCell>
                  <TableCell
                    align="left"
                    component={"th" as any}
                    scope="row"
                    variant="cell"
                    className="flex flex-1"
                    sx={{ wordBreak: "break-all", cursor: "pointer" }}
                    onClick={(e) => {
                      if (triggerTime > 1000) return;
                      else showUserDetails(row.ids);
                    }}
                    onMouseDown={() => {
                      triggerTime = new Date().getTime();
                    }}
                    onMouseUp={() => {
                      let thisMoment = new Date().getTime();
                      triggerTime = thisMoment - triggerTime;
                    }}
                  >
                    {row.fornavn}
                  </TableCell>
                  <TableCell align="left" variant="cell">
                    {row.order_by_status === 3 ? (
                      <div className="flex flex-row justify-center item-center bg-[#EB6767] rounded-full">
                        <Chip label={"Washing failed"} size="small" />
                        <Chip
                          label={<EditMeterIcon />}
                          size="small"
                          sx={{ backgroundColor: "blue", paddingX: 2 }}
                          onClick={() => {
                            setRowId(`${row.ids}`);
                            setShowModal(true);
                          }}
                          color="info"
                        ></Chip>
                      </div>
                    ) : (
                      <StatusComponent id={row.order_by_status} />
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    variant="cell"
                    className="flex flex-1"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {row.email}
                  </TableCell>
                  <TableCell
                    align="left"
                    variant="cell"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {row.mobilenumber}
                  </TableCell>
                  <TableCell
                    align="left"
                    variant="cell"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {row.personnummer}
                  </TableCell>
                  <TableCell
                    align="left"
                    variant="cell"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {row.bolig}
                  </TableCell>
                  <TableCell
                    align="left"
                    variant="cell"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {row.updated_at}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          {!isLoading && emptyRow > 0 && emptyRow !== 13 && (
            <TableBody>
              <TableRow style={{ height: 53 * emptyRow }}>
                <TableCell colSpan={9} />
              </TableRow>
            </TableBody>
          )}

          {!isLoading && emptyRow === 13 && (
            <TableBody>
              <TableRow style={{ height: 53 * emptyRow }}>
                <TableCell colSpan={9}>
                  <div className="flex items-center justify-center">
                    <Typography>
                      No data available for the selected filters. Kindly reset
                      or update your filter selection.
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {isLoading && (
            <TableBody>
              <TableRow style={{ height: 53 * 13 }}>
                <TableCell colSpan={9}>
                  <div className="flex items-center justify-center">
                    <MoonLoader
                      color={"#36d7b7"}
                      loading={isLoading || isFetching}
                      cssOverride={{
                        display: "block",
                        margin: "0 auto",
                        borderColor: "red",
                      }}
                      size={150}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Pagination
        count={pagesCount}
        defaultPage={1}
        page={page}
        className="flex items-center justify-center pt-2"
        showFirstButton
        showLastButton
        color="primary"
        size="large"
        renderItem={(item) => (
          <PaginationItem
            // color="primary"
            slots={{
              previous: () => <MdKeyboardArrowLeft size={24} color="#43AD8C" />,
              next: () => <MdKeyboardArrowRight size={24} color="#43AD8C" />,
              first: () => (
                <KeyboardDoubleArrowLeftIcon sx={{ color: "#43AD8C" }} />
              ),
              last: () => (
                <KeyboardDoubleArrowRightIcon sx={{ color: "#43AD8C" }} />
              ),
            }}
            {...item}
          />
        )}
        disabled={isLoading}
        onChange={handleChange}
      />
      <ChangeMeterModal
        showModal={showModal}
        setShowModal={setShowModal}
        rowId={rowId}
      />
    </>
  );
};
const SortingCustomComponent = React.memo(({ name }: any) => {
  const sortState = useSelector((state: any) => state.sortSlice);

  return (
    <Stack pl={0.5} spacing={2}>
      {sortState[name].asc && (
        <BsArrowUp size={14} style={{ stroke: "#3F556F", strokeWidth: ".6" }} />
      )}
      {sortState[name].desc && (
        <BsArrowDown
          size={14}
          style={{ stroke: "#3F556F", strokeWidth: ".6" }}
        />
      )}
    </Stack>
  );
});

const StatusComponent: React.FC<StatusComponentProps> = ({ id }) => {
  let component: any;
  switch (id) {
    case 1:
      component = <Chip label={"New"} variant="new" size="small" />;
      break;
    case 2:
      component = (
        <Chip label={"Washing succeeded"} variant="success" size="small" />
      );
      break;
    case 4:
      component = (
        <Chip label={"On-boarding succeeded"} variant="success" size="small" />
      );
      break;
    case 5:
      component = (
        <Chip label={"On-boarding failed"} variant="fail" size="small" />
      );
      break;
    case 6:
      component = <Chip label={"Bad credit"} variant="warning" size="small" />;
      break;
    case 7:
      component = (
        <Chip
          label={"Manual Reading Meter"}
          variant="warning"
          size="small"
          sx={{
            backgroundColor: "#008080",
          }}
        />
      );
      break;
      case 8:
        component = (
          <Chip label={"Credit Report Blocked"} variant="warning" size="small" />
        )
        break;      
  }

  return component;
};

export default UserTable;
